export const resumeData = {
  main: {
    name: "Angus Hsu",
    occupation: "Software engineer",
    description:
      "I'm an experienced software engineer who constantly seeks out innovative solutions to everyday problems. In my years in this industry, I've honed my analytical thinking and collaboration skills, and I love working with a team",
    image: "angus-self.jpg",
    bio: "I'm an experienced software engineer who constantly seeks out innovative solutions to everyday problems. In my years in this industry, I've honed my analytical thinking and collaboration skills, and I love working with a team.",
    contactMessage:
      "Please contact me if you've any interest in what I've post here!!",
    email: "apangus611@gmail.com",
    phone: "+886-918540249",
    address: {
      city: "Taipei (Taiwan)",
      zip: "234",
    },
    website: "https://www.angushsu.me",
    social: [
      {
        name: "facebook",
        url: "https://www.facebook.com/profile.php?id=100000199500122",
        className: "fab fa-facebook",
      },
      {
        name: "linkedin",
        url: "https://www.linkedin.com/in/angushsu611/",
        className: "fab fa-linkedin",
      },
      {
        name: "github",
        url: "https://github.com/AngusHsu",
        className: "fab fa-github",
      },
      {
        name: "mail",
        url: "mailto:apangus611@gmail.com",
        className: "fa fa-envelope",
      },
      {
        name: "medium",
        url: "https://medium.com/@apangus611",
        className: "fab fa-medium-m",
      },
    ],
  },
  resume: {
    skillMessage: "",
    education: [
      {
        school: "National Taiwan University",
        degree: "Communication engineering (Master degree)",
        graduated: "Sep 2020",
      },
      {
        school: "National Chung Cheng University",
        degree: "Communication engineering (Bachelor degree)",
        graduated: "Jun 2016",
      },
    ],
    work: [
      {
        company: "Cubewise",
        title: "Web developer",
        years: "Jan 2022 - Present",
        description: [
          "Designed and developed an automated system capable of exporting reports to designated locations at predetermined times, streamlining data dissemination and ensuring timely access.",
          "Established of a zero-to-one web product, overseeing its progression from concept to launch using Flask, FastAPI and React.",
          "Conducted code reviews and security audits to identify and resolve vulnerabilities, ensuring the application met industry security standards.",
          "Developed a multi-page Nestjs for comprehensive yearly expense planning, enabling users to organize and track their financial goals efficiently. The application features an intuitive interface for budgeting, forecasting, and managing expenses across different categories.",
        ],
      },
      {
        company: "EMQ",
        title: "Software developer",
        years: "Aug 2019 - Jan 2022",
        description: [
          "Collaborate with Back-End engineers to integrate new functionalities.",
          "Actively participate in UI/UX decisions and UI components.",
          "Build scalable, reliable, and secure web applications.",
          "Build complex front-end logic, caching, synchronization, performance optimization.",
          "Write software to improve web app reliability (unit tests, automated testing, end to end testing).",
        ],
      },
      {
        company: "EMQ",
        title: "Operation team lead",
        years: "Dec 2018 - Jul 2019",
        description: [
          "Work with the team to develop, improve, and execute operational processes & procedures.",
          "Work closely with our Network team to understand new partner integrations, define the processes & procedures & improve ongoing integrations.",
          "Collaborate with other teams to drive system improvements that help us deliver results quicker.",
          "Drive resolutions to complicated incidents.",
          "Execute daily processes resolving any incidents efficiently.",
          "Mentor, guide and train junior staff members.",
        ],
      },
      {
        company: "EMQ",
        title: "Operation assistant",
        years: "Oct 2016 - Dec 2018",
        description: [
          "Work with the team to develop, improve, and execute operational processes & procedures.",
          "Work closely with our Network team to understand new partner integrations, define the processes & procedures & improve ongoing integrations.",
          "Collaborate with other teams to drive system improvements that help us deliver results quicker.",
          "Drive resolutions to complicated incidents.",
          "Execute daily processes resolving any incidents efficiently.",
        ],
      },
    ],
    skills: [
      {
        name: "Git",
        level: "90%",
      },
      {
        name: "Python",
        level: "85%",
      },
      {
        name: "ReactJs",
        level: "90%",
      },
      {
        name: "CSS",
        level: "90%",
      },
      {
        name: "HTML5",
        level: "90%",
      },
      {
        name: "javascript",
        level: "90%",
      },
      {
        name: "Postgresql",
        level: "70%",
      },
    ],
  },
  portfolio: {
    projects: [
      {
        title: "EMQQQ",
        category:
          "This Line bot is designed to keep you entertained, informed, and organized—all in one place!",
        image: "emqqq.png",
        url: "https://emqqq.com",
      },
      {
        title: "LinkLoom",
        category:
          "A simple and fast website for shortening and managing URLs efficiently.",
        image: "linkloom.png",
        url: "https://linkloom.link",
      },
      {
        title: "Identique",
        category:
          "A fast and secure website for instantly validating ID numbers with accuracy and ease.",
        image: "identique.png",
        url: "https://identique.org",
      },
    ],
  },
  testimonials: {
    testimonials: [
      {
        text: "My favorite things in life don’t cost any money. It’s really clear that the most precious resource we all have is time.",
        user: "Steve Jobs",
      },
    ],
  },
  medium: {
    username: "apangus611",
  },
};
